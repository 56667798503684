import React from 'react'

const Confrim = () => {
  return (
    <>
       <div className="stack-tab">
          <h4 className="text-center text-capitalize letter-3">Confirm</h4>
          <p className="text-center">ui needed</p>
      </div>
    </>
  )
}

export default Confrim
