import React from 'react'

const PreAuthorization = () => {
  return (
    <>
      <div className="stack-tab">
        <h4 className="text-center text-capitalize letter-3">Pre Authorization</h4>
        <p className="text-center">ui needed</p>
      </div>
    </>
  )
}

export default PreAuthorization
