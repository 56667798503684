import React from 'react'
import Stack from './Stack'
import Unstack from './Unstack'
import Withdraw from './Withdraw'

const StackingIndex = () => {
  return (
    <>
      <div className="other_page_layouts stackIndexBG" id="stackIndex">
        <section className="main-content-wrapper ">
          <div className="container-fluid">
            <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12 no-padding">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="tabs tabs--style5 no-padding col-lg-4 col-md-12 ">
                  <ul role="tablist w-80">
                    <li role="presentation" class="tab-control active">
                      <a href="#Stack" role="tab" data-toggle="tab" class="control-item">
                        <h6 class="tab-title">Stack</h6>
                      </a>
                    </li>
                    <li role="presentation" class="tab-control">
                      <a href="#UnStack" role="tab" data-toggle="tab" class="control-item">
                        <h6 class="tab-title">UnStack</h6>
                      </a>
                    </li>
                    <li role="presentation" class="tab-control">
                      <a href="#Withdraw" role="tab" data-toggle="tab" class="control-item">
                        <h6 class="tab-title">Withdraw</h6>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-8 col-md-12 px-lg-0">
                  <div className="stack-info-wrapper">
                    <div className="stack-card">
                      <h6 className="text-center mb-3">Number of stackers</h6>
                      <h6 className="c-primary text-center letter-2">
                        <span className="custom-hr"></span>
                        7000
                      </h6>
                    </div>
                    <div className="stack-card">
                      <h6 className="text-center mb-3">Total BAFCAPS Stacked</h6>
                      <h6 className="c-primary text-center letter-2">
                        <span className="custom-hr"></span>
                        418932323.79
                      </h6>
                    </div>
                    <div className="stack-card">
                      <h6 className="text-center mb-3">APY</h6>
                      <h6 className="c-primary text-center letter-2">
                        <span className="custom-hr"></span>
                        15.00%
                        </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-padding">
                <div class="tab-content stack-content-padding">
                  <Stack/>
                  <Unstack/>
                  <Withdraw/>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12">
              <div className="stack-right-wrapper">
                  <div className="stack-card">
                    <p className="mb-4">Stacked</p>
                    <div className="custom-hr"></div>
                    <h6 className="letter-2">0.0000</h6>
                  </div>
                  <div className="stack-card">
                    <p className="mb-4">Unstacked</p>
                    <div className="custom-hr"></div>
                    <h6 className="letter-2">0.0000</h6>
                  </div>
                  <div className="stack-card grid-span-2">
                    <p className="mb-4 ">Rewards</p>
                    <div className="custom-hr"></div>
                    <h6 className="letter-2">0.0000</h6>
                    <div className="d-flex mt-2 stackindexbutton justify-content-evenly">
                      <button type="button" className="btn btn--large btn--primary blacktext mt-2 p-3">Stack</button>
                      <button type="button" className="btn btn--large btn--primary blacktext mt-2 ml-0 p-3">Withdraw</button>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default StackingIndex
