import React from 'react'

const Checkpoint = () => {

  const checklist = ["connect with metatask" ,"BAFCAPS Available to deposit" ,"BNB Avilable in wallet" , 'Eligible to stake']

  return (
    <>
      <div className="stack-tab">
          <h4 className="text-center text-capitalize letter-3">checkpoint</h4>
          <p className="text-center">The Following Condition must be met to proceed</p>
          <div className="checkpoint-card-wrapper">
            {checklist.map((list , index) => (
                <div className="checkpoint-card" key={index}>
                  <div className="d-flex  justify-content-between">
                    <h6 className="text-capitalize">{list}</h6>
                    <div class="radio radio--style4 mb-0 mt-2">
                      <label>
                        <input type="radio" name="optionsRadios3" />
                        <span class="circle"></span><span class="check"></span>
                      </label>
                    </div>
                  </div>
                  <p className="mt-2 letter-no-spacing text-justify">What is Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has been the industry's standard dummy text ever since the 1500s </p>
                </div>
            ))}
          </div>
          <div class="checkbox checkbox--style4">
						<label className="whitecolor">
							<input type="checkbox" name="optionsCheckboxes3"/>
              <span class="checkbox-material"><span class="check"></span></span>
							I have read Terms And Condition
						</label>
					</div>
      </div>
    </>
  )
}

export default Checkpoint
