import React, { useState, useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Base from "./Base";
import store from "./store";
import { createBrowserHistory as createHistory } from "history";
import "react-redux-notify/dist/ReactReduxNotify.css";
import ScrollToTop from "./Helper/ScrollToTop";
import LandingPageIndex from "./LandingPage/LandingPageIndex";
import AuthProvider from "./account/auth/AuthProvider";
import { apiConstants } from "./Constant/constants";
import configuration from "react-global-configuration";

const history = createHistory();

const App = () => {

  const [configLoading, setConfigLoading] = useState(true);

  useEffect(() => {
    fetchConfig();
  }, []);

  const fetchConfig = async () => {
    try {
      const response = await fetch(apiConstants.settingsUrl);

      console.log("response", response);
      const configValue = await response.json();

      configuration.set({ configData: configValue.data }, { freeze: false });
      setConfigLoading(false);
    } catch (error) {
      configuration.set({ configData: [] }, { freeze: false });
      setConfigLoading(false);
    }
  };

  return (
    <Provider store={store}>
      <Router>
        <ScrollToTop />
        {!configLoading && (
          <AuthProvider>
            <Base />
          </AuthProvider>
        )}
      </Router>
    </Provider>
  );
}

export default App;
