import React,{useState} from 'react'
import AmountToStake from './stackComponents/AmountToStake';
import Checkpoint from './stackComponents/Checkpoint';
import Confirmation from './stackComponents/Confirmation';
import Confrim from './stackComponents/Confrim';
import PreAuthorization from './stackComponents/PreAuthorization';

const Stack = () => {

  const [activeStackTab , setActiveStackTab] = useState(1);

  const hanldeActiveStackTab = (index) => {
    setActiveStackTab(index)
  }

  const RenderComponent = (index) => {
    switch(index) {
      case 1:
        return <Checkpoint/>;

      case 2:
        return <AmountToStake/>

      case 3:
        return <PreAuthorization/>

      case 4:
        return <Confrim/>

      case 5:
        return <Confirmation/>

      default:
        return <Checkpoint/>;
    }
  }

  return (
    <>
      <div role="tabpanel" class="tab-pane fade active" id="Stack">
          <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12 no-padding">
            <h4 className="text-bold text-center letter-3">Stake Your BAFCAPS</h4>
            <div className="col-lg-10 col-xs-12 col-sm-12 col-md-10 col-lg-offset-1 col-md-offset-1 no-padding">
              <div className="stack-tab-wrapper">
                <div className="stack-tab">
                  <ul className="stack-tab-ul">
                    <li className={`stack-list ${activeStackTab == 1 ?  "active" : ""}`} onClick={() => hanldeActiveStackTab(1)}>
                        <div className="content">
                          <div className="icon ">
                            <i class="fas fa-bars"></i>
                          </div>
                          <p>Confirmation</p>
                        </div>
                    </li>
                    <li className={`stack-list ${activeStackTab == 2 ?  "active" : ""}`} onClick={() => hanldeActiveStackTab(2)}>
                        <div className="content">
                          <div className="icon">
                           <i class="fas fa-dollar-sign"></i>
                          </div>
                          <p className="">Amount to stack</p>
                        </div>
                    </li>
                    <li className={`stack-list ${activeStackTab == 3 ?  "active" : ""}`} onClick={() => hanldeActiveStackTab(3)}>
                        <div className="content">
                          <div className="icon">
                            <i class="fas fa-user-check"></i>
                          </div>
                          <p className="">pre-authorization</p>
                        </div>
                    </li>
                    <li className={`stack-list ${activeStackTab == 4 ?  "active" : ""}`} onClick={() => hanldeActiveStackTab(4)}>
                        <div className="content">
                          <div className="icon">
                            <i class="fas fa-shield-alt"></i>
                          </div>
                          <p className="">confirm</p>
                        </div>
                    </li>
                    <li className={`stack-list ${activeStackTab == 5 ?  "active" : ""}`} onClick={() => hanldeActiveStackTab(5)}>
                        <div className="content">
                          <div className="icon">
                            <i class="far fa-check-circle"></i>
                          </div>
                          <p className="">confirmation</p>
                        </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12">
            {RenderComponent(activeStackTab)}
          </div>
          <div className="col-lg-12 col-xs-12 col-sm-12 col-md-12">
            <div className="stackbuttonWrapper">
                <div className="stackPrevButton" onClick={() => hanldeActiveStackTab(prev => prev - 1)}>
                  <svg class="woox-icon"><use xlinkHref="#icon-arrow-left"></use></svg>
                </div>
                <div className="stackPrevButton ml-5"  onClick={() => hanldeActiveStackTab(prev => prev + 1)}>
                  <svg class="woox-icon"><use xlinkHref="#icon-arrow-right"></use></svg>
                </div>
            </div>
          </div>
      </div>
    </>
  )
}

export default Stack
