import React from 'react'

const AmountToStake = () => {
  return (
    <>
      <div className="stack-tab">
          <h4 className="text-center text-capitalize letter-3">Amount to Stack</h4>
          <p className="text-center">ui needed</p>
      </div>
    </>
  )
}

export default AmountToStake
