import React from 'react'

const Withdraw = () => {
  return (
    <>
      <div role="tabpanel" class="tab-pane fade" id="Withdraw">
      <h3 className="text-center">Withdraw</h3>
      </div>
    </>
  )
}

export default Withdraw
